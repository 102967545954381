import qs from 'qs';
import { isEmpty } from 'lodash-es';
import { GaTracker } from './GaTracker';
import { MessageType } from '../components/clearstep/proto/logging_pb';
import { Partner } from '../components/clearstep/proto/partners_pb';
import { EventCategory } from './GaEvents';
import { createEvent } from '../api/EventLog';
import { SpecialistTypes } from '../components/tailwind_clearstep/Partners/symptom_check/CareDetails';
import { THEME } from '../components/clearstep/util/Theme';
import { handleMyChartRedir } from './MyChartLauncher';
import { callPartnerEventLog } from '../components/tailwind_clearstep/Partners/event_log/EventLogPartnerCustomizations';
import '../components/clearstep/Types';
import { prepareText } from '../components/clearstep/TextUtil';
import { getURLParamValue } from '../components/clearstep/util/AppHelpers';
export { MessageType };
export function logEvent(type, data, options) {
    var _a;
    if (data === void 0) { data = {}; }
    var _b = options || {}, partner = _b.partner, customData = _b.customData;
    var hasConvoId = window.conversationId;
    var hasSessionId = window.sessionId;
    var widgetSessionId = getURLParamValue('widgetSessionId');
    if (widgetSessionId) {
        data.widgetSessionId = widgetSessionId;
    }
    callPartnerEventLog(type, THEME.partner, data, { customData: customData });
    if (!hasConvoId && !hasSessionId) {
        return;
    }
    // Ability to callPartnerEventLog() but not logEvent to db and GA
    if (!type) {
        return;
    }
    // Note: while Google Analytics passes `event` calls to Gtag manager, it
    // does not work if GA4React is not initialized (TypeError: window.gtag is
    // not a function). If events still need to be logged to Gtag, custom
    // trigger can be called via `window.dataLayer.push` (see pattern for BSWH)
    // https://support.google.com/tagmanager/answer/7679219?hl=en&ref_topic=7679108&sjid=2208473365895046771-NC
    if (!isEmpty((_a = THEME.analyticsCustomizations) === null || _a === void 0 ? void 0 : _a.partnerGtm)) {
        GaTracker.event(type, partner || Partner.UNKNOWN_PARTNER, EventCategory[type]);
    }
    submitLogEvent(type, data);
}
// Sends log event to logging endpoint. Separated from logEvent for cases where
// we want to skip GA tracking.
export function submitLogEvent(type, data) {
    var _a, _b, _c, _d;
    if (data === void 0) { data = {}; }
    var convoId = (_b = (_a = window.conversationId) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : '';
    var sessionId = (_d = (_c = window.sessionId) === null || _c === void 0 ? void 0 : _c.toString()) !== null && _d !== void 0 ? _d : '';
    if (sessionId) {
        data.sessionId = sessionId;
    }
    var queryParams = {
        type: type,
        conversation_id: convoId,
        partner: THEME.partnerHostName,
    };
    createEvent(data, queryParams);
}
function parseUrl(url, partner) {
    var parsedUrl = url.replace(SpecialistTypes.OTOLARYNGOLOGY_ENT, 'otolaryngology-(ent)');
    if (partner === Partner.NOVANT) {
        var _a = url.split('?'), path = _a[0], rawQS = _a[1];
        var parsedQS = qs.parse(rawQS);
        parsedQS.utm_source = 'CS';
        parsedUrl = "".concat(path, "?").concat(qs.stringify(parsedQS));
    }
    return parsedUrl;
}
export function linkToAndLogConvo(redirectUrl, type, data, options) {
    if (data === void 0) { data = {}; }
    if (!window.conversationId) {
        return;
    }
    linkToAndLog(redirectUrl, type, data, options);
}
export function linkToAndLog(redirectUrl, type, data, options) {
    if (data === void 0) { data = {}; }
    var _a = options || {}, partner = _a.partner, redirFunction = _a.redirFunction;
    logEvent(type, data, options);
    // Moble MyChart URLs should redirect to myChart app if configured for current partner
    if (handleMyChartRedir(redirectUrl)) {
        return;
    }
    if (redirFunction) {
        redirFunction(redirectUrl);
        return;
    }
    var parsedUrl = parseUrl(redirectUrl, partner);
    window.open(parsedUrl, '_blank', 'noopener,noreferrer');
}
export var openUrlInBrowser = function (redirectUrl) {
    // Ensures that URL is opened in current window (even if user has app installed)
    // i.e. for users with the Curai App clicking Tufts 'Start your visit' curaihealth.com link
    // breaks web-browser implementation however
    var link = document.createElement('a');
    link.href = redirectUrl;
    link.target = '_self';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
export var openUrlInNewTab = function (redirectUrl) {
    var link = document.createElement('a');
    link.href = redirectUrl;
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
/*
    Helpers for logging Questions & Answers
*/
export function getCategory(question, currCategory) {
    if (currCategory === 'other') {
        if (question === 'How old are {you|they}?') {
            return 'age';
        }
        if (question === 'What sex was originally listed on {your|their} birth certificate?') {
            return 'gender';
        }
    }
    return 'other';
}
export function getQuestionAnswerData(oldMessages, newMessages, isProxyUser) {
    var question = [];
    var answer = [];
    var category = 'other';
    var unanswered = true;
    for (var i = newMessages.length - 1; i >= oldMessages.length - 1; i--) {
        var message = newMessages[i];
        if (message.is_from_user) {
            answer.unshift({
                text: prepareText(message.text, { isProxyUser: isProxyUser }),
                is_from_user: message.is_from_user,
            });
            unanswered = false;
        }
        else if (!unanswered) {
            while (i >= 0 && newMessages[i].is_from_user !== true) {
                category = getCategory(newMessages[i].text, category);
                question.unshift({
                    text: prepareText(newMessages[i].text, { isProxyUser: isProxyUser }),
                    is_from_user: newMessages[i].is_from_user,
                });
                i -= 1;
            }
        }
    }
    return { question: question, category: category, answer: answer };
}
