import { SourceOfCare } from '../proto/results_pb';
/* CORONAVIRUS */
export function triageColor(careTypeCode) {
    switch (careTypeCode) {
        case SourceOfCare.Type.AMBULANCE:
        case SourceOfCare.Type.ER:
            return '#ff695b';
        case SourceOfCare.Type.URGENT_CARE:
        case SourceOfCare.Type.TELEMEDICINE:
        case SourceOfCare.Type.NURSE_REACHOUT:
        case SourceOfCare.Type.PRIMARY_CARE:
        case SourceOfCare.Type.DENTIST:
            return '#ffa100';
        case SourceOfCare.Type.HOME:
        case SourceOfCare.Type.UNKNOWN:
            return '#30a661';
        default:
            return '#ffa100';
    }
}
/* GENERAL */
export function getSentenceCase(value) {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
}
export function removeDashes(value) {
    if (!value) {
        return '';
    }
    return value.split('-').join(' ');
}
export function isEmergencyTriageRec(careType) {
    return (!!careType && (careType === SourceOfCare.Type.ER || careType === SourceOfCare.Type.AMBULANCE));
}
export function isDoctorTriageRec(careType) {
    return careType === SourceOfCare.Type.TELEMEDICINE || careType === SourceOfCare.Type.PRIMARY_CARE;
}
